import React, {createContext, useContext, useState} from "react";
import {checkStatus} from "./Utils";

// centralise the api url
import apiurl from "../urls.json";

const AuthContext = createContext('');

/**
 * Component which handle authentication
 * @param children
 * @returns {*}
 * @constructor
 */
export const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null);

    /**
     * Get the connection token
     * @param credentials
     * @returns {Promise<any>}
     */
    const signIn = credentials => {
        return fetch(apiurl.apiurl + "/signin", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(credentials)
        })
            .then(checkStatus)
            .then(res => res.json())
            .then(data => {

                if (!data.access_token){
                    throw Error(data.message)
                } else {
                    window.localStorage.setItem("token", data.access_token);
                    window.localStorage.setItem("refreshToken", data.refresh_token);
                    setUser(data.user);
                }

            });
    };

    /**
     * Refresh connection token
     * @returns {Promise<any>}
     */
    const refreshToken = () => {
        const token = window.localStorage.getItem('refreshToken');
        return fetch(apiurl.apiurl + "/refreshToken", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json"
            }
        })
            .then(checkStatus)
            .then(res => res.json())
            .then(data => {
                if (!data.access_token){
                    throw Error(data.message)
                } else {
                    window.localStorage.removeItem("token");
                    window.localStorage.setItem("token", data.access_token);
                }

            });
    };

    /**
     * Remove tokens
     */
    const signOut = () => {
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("refreshToken");
        setUser(null);

        window.location.replace('/')
    };

    return (
        <AuthContext.Provider value={{user, signIn: signIn, refreshToken: refreshToken, signOut: signOut}}>
            {children}
        </AuthContext.Provider>
    );
};

// transform to hook
export const useAuth = () => useContext(AuthContext);
