import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

import Welcome from "./Welcome";
import Results from "./Results";
import SignIn from "./SignIn";
import {AuthProvider} from "../utils/authProvider";


const App = () => {
    return <>
        <AuthProvider>
            <Router>
                <Switch>
                    <Route exact path="/">
                        <SignIn />
                    </Route>
                    <Route exact path="/home">
                        <Welcome/>
                    </Route>
                    <Route path="/:id/results">
                        <Results/>
                    </Route>
                </Switch>
            </Router>
        </AuthProvider>
    </>;
};

export default App;
