import React, {useEffect, useRef, useState} from 'react';
import {useLocation} from "react-router-dom";
import {Badge, Col, ProgressBar, Row, Spinner, Table} from "react-bootstrap";
import ButtonsResults from "./ButtonResult";
import {useDataFromUrl} from "../utils/Utils";
import medtronic_utils from "../utils/medtronic_utils";
import {useAuth} from "../utils/authProvider";
import AddName from "./AddName";
import common_utils from "../utils/common_utils";
import Img from "react-image";
import logo from "../img/ircad-vert.svg"

// centralise the api url
import apiurl from "../urls.json";


/**
 * Main component which display the result
 * @returns {*}
 * @constructor
 */
const Results = () => {
    const [courseName, setCourseName] = useState('');
    const [editName, setEditName] = useState(false);
    const [nbForms, setNbForms] = useState([]);
    const location = useLocation();
    const url = apiurl.apiurl + '/' + location.pathname.split('/', 2)[1];
    const {loading, error, data: answers} = useDataFromUrl(url);
    const {refreshToken} = useAuth();
    const token = window.localStorage.getItem('token');
    !token && window.location.replace('/');

    // When Component did mount, refresh the token
    useEffect(() => refreshToken, []);
    useEffect(() => {
        answers["results"] && setNbForms(common_utils.getNbForm(answers["results"]));
        setCourseName(answers["courseName"]);
    }, [answers]);

    return <>
        <Row>
            <Col sm lg={2}>{/* Empty Col*/}</Col>
            <Col xs>
                <div className="titles">
                    <h1 className="title">FORM READER</h1>
                    <h2>{courseName}</h2>
                </div>
                {!editName && !loading && <ButtonsResults answer={answers}/>}
            </Col>
            <Col xl lg={2}>{/* Empty Col*/}</Col>
        </Row>
        <Row>
            <Col xs>{/* Empty Col*/}</Col>
            <Col sm>
                <br/>
                {loading ? <Badge variant="primary">Loading<Spinner size="sm" animation="grow"/></Badge> : <>
                    {nbForms &&
                    <div>Completion :
                        <ProgressBar label={<b>{nbForms[0] + "/" + nbForms[1]}</b>}
                                     variant={(nbForms[0] !== nbForms[1]) ? "info" : "success"}
                                     striped
                                     now={Math.round((nbForms[0] / nbForms[1]) * 100)}/>&nbsp;</div>}
                    <Table responsive bordered striped hover size="md">
                        {!editName && <thead>
                        <tr>

                            <Cats answers={answers}/>
                        </tr>
                        </thead>}
                        <tbody>
                        <PeopleResults answers={answers} editName={editName} setEditName={setEditName}/>
                        </tbody>
                    </Table></>}
            </Col>
            <Col xs>{/* Empty Col*/}</Col>
        </Row>
    </>;
};

/**
 * Categories of the form
 * @param answers
 * @returns {*}
 * @constructor
 */
const Cats = ({answers}) => {

    let tabCategories = Object.values(answers['order']);
    tabCategories = medtronic_utils.catsMedtronic(tabCategories);

    return <>
        {tabCategories && tabCategories.map(s => s)}
    </>
};

/**
 * Format the results
 * @param answers
 * @param editName
 * @param setEditName
 * @returns {*}
 * @constructor
 */
const PeopleResults = ({answers, editName, setEditName}) => {
    const resultId = window.location.pathname.split('/', 2)[1];
    const [defaultUrl,] = useState(apiurl.apiurl + `/${resultId}`);
    const [name, setName] = useState('');

    const usersRefs = useRef([]);

    const usersResults = medtronic_utils.getMedtronicResult(answers);


    const handleClick = (i) => {
        // Get name in the <tr>
        const attendeeName = usersRefs.current[i].cells[0].innerText.split(' ')
            .filter(word => word !== 'Not' && word !== 'checked' && word !== 'yes' && word !== 'signed' && word !== 'phone' && word !== 'email')
            .join(' ');
        setName(attendeeName);
        setEditName(true);
    };

    return <>
        {editName ? <AddName defaultUrl={defaultUrl} resultId={resultId} answer={answers} personName={name}/> :
            usersResults.map((person, i) => (
                <tr key={'Person' + i}
                    ref={ref => {
                        usersRefs.current[i] = ref;
                    }}
                    onClick={() => handleClick(i)}>{person.map((choice, j) => (
                    <td key={'Result' + j}>{choice}</td>
                ))
                }</tr>
            ))}
    </>
};

export default Results
