import React, {useEffect, useRef, useState} from "react";
import {useDataFromUrl} from "../utils/Utils";
import common_utils from "../utils/common_utils";
import medtronic_api from "../utils/medtronic_api";
import {Button, ButtonGroup, Form, ListGroup, Spinner} from "react-bootstrap";
import {useAuth} from "../utils/authProvider";

// centralise the api url
import apiurl from "../urls.json";

/**
 *
 * @param answer
 * @param resultId
 * @param defaultUrl
 * @param personName
 * @returns {*}
 * @constructor
 */
const AddName = ({answer, resultId, defaultUrl, personName}) => {
    const [name, setName] = useState(personName);
    const [comment, setComment] = useState('');
    const [closeNames, setCloseNames] = useState([]);
    const [urlAttendee,] = useState(apiurl.apiurl + `/attendee/${resultId}`);
    const [sending, setSending] = useState(false);
    const {loading, error, data: courseAttendee} = useDataFromUrl(urlAttendee);
    const {refreshToken} = useAuth();

    const textInput = useRef(null);
    let idFormUrl;

    personName ? idFormUrl = common_utils.getImgIdUrl(answer.results, resultId, personName)
        : idFormUrl = common_utils.getImgUrl(answer.results, resultId); // 0 is idForm, 1 is url


    useEffect(() => {
            idFormUrl[1] && !loading && !sending && textInput.current.focus();
        },
        [idFormUrl[1], loading, sending]);


    const handleName = (e) => {
        setName(e.target.value);
        name && setCloseNames(common_utils.handleCloseNamesList(closeNames, courseAttendee.attendee, name))
    };

    const handleNext = () => {

        const data = JSON.stringify(
            common_utils.addNameJson(answer.results, name, comment, courseAttendee.attendee, idFormUrl[0], resultId));

        setSending(true);
        medtronic_api.sendUpgradeData(defaultUrl, data)
            .then(r => {
                idFormUrl = common_utils.getImgUrl(answer.results, resultId);
                setSending(false);
                resetComp();
            })
            .catch(e => {
                setSending(false);
                console.log('Error :' + e);
                resetComp();
            });

    };

    const handleUpdate = () => {
        const data = JSON.stringify(
            common_utils.updateNameJson(answer.results, name, comment, courseAttendee.attendee, idFormUrl[0], resultId));

        setSending(true);
        medtronic_api.sendUpgradeData(defaultUrl, data)
            .then(r => {
                // eslint-disable-next-line no-restricted-globals
                location.reload();
                setSending(false);
            })
            .catch(e => {
                setSending(false);
                console.log('Error :' + e);
            });
    };

    const resetComp = () => {
        refreshToken();
        setName('');
        setComment('');
        // textInput.current.focus();
    };

    return <>
        {(loading || sending) ? <><Spinner animation={"border"} variant={"primary"}/> </> :
            idFormUrl[1] ?
                <ListGroup horizontal>
                    <ListGroup.Item>
                        <img src={idFormUrl[1]} alt=" Form Error !" width={500}/>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <Button variant="outline-primary" size="sm" onClick={() => document.location.reload()}>
                            ◃ Back to the grid</Button>
                        <Form>
                            <Form.Group controlId="formName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control autoComplete='off'
                                              type="text"
                                              placeholder="Enter name"
                                              value={name}
                                              onChange={handleName}
                                              onKeyPress={(e) => {
                                                  e.key === 'Enter' && (personName ? handleUpdate() : handleNext())
                                              }}
                                              ref={textInput}

                                />
                            </Form.Group>
                            {closeNames !== [] &&
                            <ButtonGroup vertical>
                                {closeNames.map(value =>
                                    <Button variant={"success"} onClick={() => {
                                        setName(value);
                                        setCloseNames([]);
                                        textInput.current.focus();
                                    }}>⋗ {value}</Button>
                                )}
                            </ButtonGroup>}
                            <Form.Group controlId="formComments">
                                <Form.Label>Comments</Form.Label>
                                <Form.Control as="textarea"
                                              rows="4"
                                              value={comment}
                                              onChange={e => setComment(e.target.value)}
                                              onKeyPress={(e) => {
                                                  e.key === 'Enter' && (personName ? handleUpdate() : handleNext())
                                              }}/>
                            </Form.Group>
                            {!personName ? <Button variant="outline-info" onClick={handleNext}>Next ⊳</Button>
                                : <Button variant="outline-primary" onClick={handleUpdate}>Update ⊙</Button>}
                        </Form>
                    </ListGroup.Item>

                </ListGroup>
                : <><p ref={textInput}>There is no more name to add. </p>
                    <Button variant="outline-primary" size="sm" onClick={() => document.location.reload()}>
                        ◃ Back to the grid</Button>
                </>
        }
    </>
};

export default AddName
