import React, {useEffect, useState} from 'react';
import {Alert, Button, Col, Container, Dropdown, DropdownButton, Fade, Spinner, Table} from 'react-bootstrap';
import Img from 'react-image'
import {useAuth} from '../utils/authProvider';
import {useDataFromUrl} from '../utils/Utils'
import common_utils from "../utils/common_utils";
import medtronic_api from "../utils/medtronic_api";
import logo from "../img/ircad-vert.svg";
// centralise the api url
import apiurl from "../urls.json";


/**
 * Main component of the home. It display all the courses and button to process it
 * @returns {*}
 * @constructor
 */
const Welcome = () => {
    const [dataUrl,] = useState(apiurl.apiurl);
    const [activeTemplate, setActiveTemplate] = useState('default');
    const {loading, error, data} = useDataFromUrl(dataUrl);
    const {refreshToken, signOut} = useAuth();


    // When the component did mount, if there is not auth token, it redirect to login page, else it refresh token
    useEffect(() => {
        !window.localStorage.getItem('token') && window.location.replace('/');
        refreshToken()
    }, []);

    useEffect(() => {if (data.templates) {setActiveTemplate(data.templates[0]);}},
        [data.templates])

    return (
        <Container>
            <Col>{/* Empty Col*/}</Col>
            <Col md="auto">
                <div className="titles"> {/*Header */}
                    <h1 className="title">FORM READER <Img className="logo" src={logo}/></h1>
                    <span id='disconnect'><Button variant='outline-dark' onClick={signOut}>Ⓘ Sign Out</Button></span>
                    <h2>COURSES</h2>
                </div>
                {loading ? <div><Spinner animation="grow" size="sm"/> Loading, please wait.</div>
                    : error ? error.message : <Fade in={!loading}>
                        <div>
                            <DropdownButton variant="light" id="template" title={activeTemplate}>
                                {data.templates.map(t => {
                                    return <Dropdown.Item onClick={() => {setActiveTemplate(t)}}>{t}</Dropdown.Item>
                                })}
                            </DropdownButton>
                            <CourseList list={data.courses} template={activeTemplate}/>
                        </div></Fade>}
            </Col>
            <Col>{/* Empty Col*/}</Col>
        </Container>
    )
};

/**
 * Display the List of courses
 * @param list
 * @param template
 * @returns {*}
 * @constructor
 */
export const CourseList = ({list, template}) => {
    // If list cannot be pull, it sign out
    const {signOut} = useAuth();
    list === undefined && signOut();

    // Get list of courses
    const appropriateCourses = common_utils.getAppropriateCourses(list, template);

    return <>
        <Table responsive hover>
            <thead>
            <tr>
                <th>Date</th>
                <th>Course</th>
                <th>{/* Empty Col*/}</th>
                <th>Medtronic</th>
            </tr>
            </thead>
            <tbody>
            {appropriateCourses.map(c => c)}
            </tbody>
        </Table>
    </>
};

/**
 * Display Input for pdf, button to process, link to results
 * @param l
 * @param setUpdate
 * @param template
 * @returns {*}
 * @constructor
 */
export const DocumentSender = ({l, setUpdate, template}) => {
    const [exist, setExist] = useState(false);
    const [url, setUrl] = useState(apiurl.apiurl + "/" + l.id);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [resultStats, setResultsStats] = useState([]);
    const {loading: resultLoading, error: resultError, data: answers} = useDataFromUrl(url);
    const {refreshToken, signOut} = useAuth();

    // When the list change, it change parameters of the Component
    useEffect(() => {
        !setUpdate ? setUrl(apiurl.apiurl + `/${l.id}/${template}`)
            : setUrl(apiurl.apiurl + `/${l.id}/update`)
    }, [l]);
    useEffect(() => setExist(l.medtronic), [l.medtronic]);
    useEffect(() =>
        answers["results"] && setResultsStats(common_utils.getNbForm(answers["results"])), [answers]);

    // When the pdf upload is add, it store it in state file
    const handleChange = (e) => {
        const data = new FormData();
        data.append('file', e.target.files[0]);
        setFile(data);
    };

    // Clean the pdf stored locally
    const removeFile = () => setFile(null);

    // Post the data to the server
    const postDocument = () => {
        setLoading(true);
        medtronic_api.postFile(url, file)
            .then(() => {
                setLoading(false);
                setUpdate && setUpdate(false);
                setExist(true);
            })
            .catch(err => {
                removeFile();
                setLoading(false);
                setError(true);
                console.log(err);
            });

    };

    const closeErrorAlert = () => {
        refreshToken();
        setUpdate && setUpdate(false);
        // eslint-disable-next-line no-restricted-globals
        location.reload();
        setError(false);
    };

    return <>
        {exist /* When course is already processed */ ?
            <Button
                variant={resultStats[0] && (resultStats[0] === resultStats[1]) ? "success" : "primary"}
                href={`${l.id}/results`}
                disabled={loading}>
                ≣ Results {resultStats[1] && ("(" + resultStats[0] + "/" + resultStats[1] + " filled)")}</Button>
            :
            error ?
                <Alert key={'processError'} variant="danger" onClose={closeErrorAlert} dismissible>
                    Oops, we didn't manage to process your file...
                </Alert>
                :
                file ? <><Button
                        variant="info"
                        onClick={postDocument}
                        disabled={loading}>
                        {loading ? <><Spinner animation="grow" size="sm"/> Processing, please
                            wait</> : '◉ Process Data'}</Button>
                        {!loading && <Button
                            variant="outline-danger"
                            onClick={removeFile}>
                            ✘ Remove File</Button>} </>
                    : <input type="file" onChange={handleChange} accept="application/pdf" value={file}/>}
    </>
};

export default Welcome
