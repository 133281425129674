import {Badge, OverlayTrigger, Tooltip} from "react-bootstrap";
import React from "react";

const medtronic_utils = {
    /**
     * Return Medtronic feedback form categories
     * @param tabCategories
     * @returns {*[]}
     */
    catsMedtronic: (tabCategories) => {
        let res = [];
        res.push(<td key={'CatPerson'}>Person </td>);
        res.push(<td key={'CatRate'}>Rate</td>);

        tabCategories.map(cat =>
            /\d/.test(cat) // test if there is a number in cat
            && res.push(<OverlayTrigger
                key={'top ' + cat}
                placement={'bottom'}
                overlay={
                    <Tooltip id={`tooltip-${cat}`}>
                        {cat}
                    </Tooltip>
                }
            >
                <td>{cat.toString().split(' ', 1)[0]}</td>
            </OverlayTrigger>)
        );

        return res
    },

    /**
     * Return the result tab of medtronic feedback form
     * @param answers
     * @returns {[]}
     * @constructor
     */
    getMedtronicResult: (answers) => {
        const tabCategories = medtronic_utils.getTabMedtronic(Object.values(answers['order']));
        const userAnswers = [];
        let res = [];


        Object.values(answers['results']).map((v) => userAnswers.push(v));

        for (let i = 0; i < userAnswers.length; i++) {
            const keyTab = Object.keys(userAnswers[i]);
            const valueTab = Object.values(userAnswers[i]);
            const rate = medtronic_utils.getRate(keyTab, userAnswers[i]);
            let j = 0;
            if (valueTab[keyTab.indexOf('yes')] || valueTab[keyTab.indexOf('emailCheckbox')] || valueTab[keyTab.indexOf('phoneCheckbox')]) {
                res[i] = [valueTab[keyTab.indexOf('Person')]]

            } else {
                res[i] = [<>{valueTab[keyTab.indexOf('Person')]} <Badge pill variant="warning">Consent not validated</Badge></>];
            }
            if (!valueTab[keyTab.indexOf('Signature')]) {
                res[i] = [<>{res[i]} <Badge pill variant="danger">Not signed</Badge></>];
            }
            res[i].push(rate);

            tabCategories.map(
                cat => {
                    j > 1 && res[i].push(valueTab[keyTab.indexOf(cat.toString())]);
                    j++;
                });
        }
        return res;
    },

    /**
     * Return the order of the medtronic categories
     * @param catOrdered
     * @returns {[string, string]}
     */
    getTabMedtronic: (catOrdered) => {
        const tabMedtronic = ['Person', 'Rate'];
        catOrdered.map(c => /\d/.test(c) && tabMedtronic.push(c));
        return tabMedtronic;
    },

    /**
     * Return the rate of a medtronic feedback form
     * @param keyTab
     * @param dictResult
     * @returns {string}
     */
    getRate: (keyTab, dictResult) => {
        let rate = "";
        keyTab.map(k => {
            !/\d/.test(k.toString())
            && (k === 'Poor' || k === 'Fair' || k === 'Good' || k === 'Excellent' || k === 'Not Registered')
            && dictResult[k] && (rate = k);
        });
        return rate;
    },


};

export default medtronic_utils
