import {useEffect, useState} from "react";
import {useAuth} from "./authProvider";

// centralise the api url
import apiurl from "../urls.json";


/**
 * Generic fetch function which get JSON from an endpoint
 *
 * @param url
 * @returns {{data: *[], loading: boolean, error: string}}
 */
export const useDataFromUrl = (url) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const token = window.localStorage.getItem('token');

    useEffect(() => {
        setLoading(true);
        let p;
        if (Array.isArray(url)) {
            p = Promise.all(url.map(u => fetch(u, {
                headers:
                    {Authorization: "Bearer " + token}
            }).then(res => res.json())))
        } else {
            p = fetch(url, {
                headers:
                    {Authorization: "Bearer " + token}
            }).then(res => res.json());
        }
        p.then(data => {
            setData(data);
            setLoading(false);
        })
            .catch(err => {
                setError(err);
                setLoading(false);
            });
    }, [url, token]);

    return {loading, error, data};

};

/**
 * Hooks that select the appropriate url for the next unprocess form
 * @param results
 * @param resultId
 * @returns {{idForm: number, url: string}}
 */
export const useLoadUrlImg = (results, resultId) => {
    const [url, setUrl] = useState('');
    const [idForm, setIdForm] = useState(1);
    const token = window.localStorage.getItem('token');

    useEffect(() => {
        for (let p in results) {
            if (p.includes('Person')) {
                setIdForm(p.substring(6));
                setUrl(apiurl.apiurl + `/get_img_form/${resultId}/${p.substring(6)}/${token}`);
                break;
            }
        }

    }, [results, resultId]);
    return {url, idForm}
};

/**
 * Check if the return data is valid
 * @param res
 * @returns {{ok}|*}
 */
export const checkStatus = res => {
    if (res.ok) {
        return res;
    } else {
        return res.text().then(msg => {
            window.localStorage.removeItem("token");
            window.localStorage.removeItem("refreshToken");

            throw new Error(msg);
        });
    }
};
