import React from "react";
import {format, isAfter, isPast, parse} from "date-fns";
import {DocumentSender} from "../Components/Welcome";
// centralise the api url
import apiurl from "../urls.json";

const common_utils = {
    /**
     * return a list of the courses sort in decreasing date order and in react Components
     * @param list
     * @returns {[]}
     */
    getAppropriateCourses: (list, template) => {
        const appropriateCourse = [];
        const datesId = []; // to compare to other dates

        list.map(l => {
            const courseDate = parse(l.date.toString(), 'yyyy/MM/dd', new Date());

            if (isPast(courseDate)) { // if the course isn't in the future
                const listLength = appropriateCourse.length;

                // Sorting where we compare to every other date and place this one (decreasing order)
                for (let i = 0; i < listLength; i++) {
                    if (isAfter(courseDate, parse(datesId[i].toString(), 'yyyy/MM/dd', new Date()))) {
                        datesId.splice(i, 0, format(courseDate, 'yyyy/MM/dd'));
                        appropriateCourse.splice(i, 0,
                            <tr key={l.id}>
                                <th>{format(courseDate, 'dd MMMM yyyy')}</th>
                                <th>{l.title}</th>
                                <th>{/* Empty Col*/}</th>
                                <th>
                                    <DocumentSender l={l} setUpdate={null} template={template}/></th>
                            </tr>
                        );
                        break;
                    }
                }
                // if the Course is the most recent
                if (listLength === appropriateCourse.length) {
                    datesId.push(format(courseDate, 'yyyy/MM/dd'));
                    appropriateCourse.push(
                        <tr key={l.id}>
                            <th>{format(courseDate, 'dd MMMM yyyy')}</th>
                            <th>{l.title}</th>
                            <th>{/* Empty Col*/}</th>
                            <th>
                                <DocumentSender l={l} setUpdate={null} template={template}/></th>
                        </tr>)
                }
            }
        });

        return appropriateCourse
    },

    /**
     * Return list of name which match with user entry
     * @param closeNames
     * @param attendees
     * @param name
     * @returns {*}
     */
    handleCloseNamesList: (closeNames, attendees, name) => {
        // Attendee is the list of attendees of a course
        Object.values(attendees).map((c) => {
            let fullName = c.firstName.toString() + " " + c.lastName.toString();
            if (c.firstName.toLowerCase().includes(name.toLowerCase()) // Add to the the list
                || c.lastName.toLowerCase().includes(name.toLowerCase())) {
                !closeNames.includes(fullName) && closeNames.push(fullName)
            } else {
                if (closeNames.includes(fullName)) { // Remove of the list
                    closeNames.splice(
                        closeNames.indexOf(fullName), 1);
                }
            }
        });
        return closeNames;
    },

    /**
     * Return the result data json with a named person
     * @param results
     * @param name
     * @param comment
     * @param attendee
     * @param idForm
     * @param resultId
     * @returns {*}
     */
    addNameJson: (results, name, comment, attendee, idForm, resultId) => {
        return common_utils.affectNameJSON(results, name,comment, attendee, idForm, resultId + 'med' + idForm);
    },

    /**
     * Update the fields
     * @param results
     * @param name
     * @param comment
     * @param attendee
     * @param person
     * @returns {*}
     */
    updateNameJson: (results, name, comment, attendee, person) => {
        let newJSON = results;
        const newJSONValuesTab = Object.values(newJSON);
        const newJSONKeysTab = Object.keys(newJSON);
        newJSONValuesTab.map((res, i) => { // list all of the results
            if (res['FormId'] === person ){
                const idForm = newJSONKeysTab[i];
                !comment && (comment = "-");
                !name && (attendee = {city: "-", country_fr: "-", firstName: "-",
                    id_contact: "-", lastName: "-", title:"-"}); // if it's an anonymous we need to replace these fields
                return common_utils.affectNameJSON(newJSON,name,comment, attendee,
                    res['FormId'], idForm);
            }
        });
        return newJSON;
    },

    /**
     * Generic function which return new json
     * @param newJSON
     * @param name
     * @param comment
     * @param attendee
     * @param idForm
     * @param key
     * @returns {*}
     */
    affectNameJSON : (newJSON, name, comment, attendee, idForm, key) => {

        if (!name || name === '' || /^[\s]+$/.test(name.toString())) { // Anonymous form
            newJSON[key].Person = 'Anonymous-' + idForm;
            newJSON[key].FormId = idForm;
            if (!(!comment || comment === '' || /^[\s]+$/.test(comment.toString()))) { // if comment is not empty
                newJSON[key].Comment = comment;
            }

            if(comment === "-") { // for update, if there is already attendee's specifics fields, replace them
               Object.assign(newJSON[key], attendee);
            }

        } else { // Named person
            newJSON[key].Person = name;
            newJSON[key].FormId = idForm;
            if (!(!comment || comment === '' || /^[\s]+$/.test(comment.toString()))) { // if comment is not empty
                newJSON[key].Comment = comment;
            }

            Object.values(attendee).map((c) => {
                let fullName = c.firstName.toString() + " " + c.lastName.toString();
                if (name === fullName) { // if name is in attendee list
                    Object.assign(newJSON[key], c);
                }
            });

        }
        return newJSON;
    },

    /**
     * Return the url of the image with resultId
     * @param results
     * @param resultId
     * @returns {[string, string]}
     */
    getImgUrl: (results, resultId) => {
        let idForm;
        let url;
        const token = window.localStorage.getItem('token');
        for (let p in results) {

            if (/^[\d]+$/.test(results[p].Person)) {
                idForm = results[p].Person;
                url = apiurl.apiurl + `/get_img_form/${resultId}/${idForm}/${token}`;
                break;
            }
        }
        return [idForm, url]
    },

    /**
     * Return the url of he image of the form idForm
     * @param results
     * @param resultId
     * @param personName
     * @returns {[*, string]}
     */
    getImgIdUrl: (results, resultId, personName) => {
        let idForm;
        let url;

        const token = window.localStorage.getItem('token');
        Object.values(results).map((person) => {
            if (person['Person'] === personName){
                idForm = person['FormId'];
                url = apiurl.apiurl + `/get_img_form/${resultId}/${idForm}/${token}`;
            }
        });

        return [idForm, url]
        //throw new Error("The name " + personName  + " don't exist !")
    },

    /**
     * return the number of persons of a course
     * @param results
     * @returns {[number, number]}
     */
    getNbForm: (results) => {
        let nbFormTotal = 0;
        let nbFormProcessed = 0;

        Object.values(results).map((person) => {
            nbFormTotal ++;
            person['FormId'] && nbFormProcessed++;
        });
        
        return [nbFormProcessed, nbFormTotal]
    },
};

export default common_utils
