import {useLocation} from "react-router-dom";
import React, {useState} from "react";
import {useAuth} from "../utils/authProvider";
import {Button, ButtonToolbar, Modal, Spinner} from "react-bootstrap";
import medtronic_api from "../utils/medtronic_api";
import AddName from "./AddName";
import {DocumentSender} from "./Welcome";

// centralise the api url
import apiurl from "../urls.json";

const ButtonsResults = ({answer}) => {
    const location = useLocation();
    const [fillingName, setFillingName] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [updating, setUpdating] = useState(false);
    const resultId = location.pathname.split('/', 2)[1];
    const [defaultUrl,] = useState(apiurl.apiurl + `/${resultId}`);
    const {refreshToken, signOut} = useAuth();

    const [l, setL] = useState({id: resultId});

    const switchFillingName = () => setFillingName(!fillingName);

    const handleDelete = () => {
        medtronic_api.deleteCourseData(defaultUrl)
            .then(r => window.location.replace("/home"))
            .catch(e => {
                console.log('Error :' + e);
                refreshToken();
                window.location.replace("/");
            });
    };

    const handleCSV = () => {
        window.location.href = apiurl.apiurl + '/CSV/' + resultId +
            '/' + window.localStorage.getItem('token');
    };

    return <>
        <br/>
        {fillingName ? answer &&
            (<AddName answer={answer} resultId={resultId} defaultUrl={defaultUrl}/>) :
            <ButtonToolbar>
                {updating ? <DocumentSender l={l} setUpdate={setUpdating} />
                    :<Button variant="outline-primary" onClick={() => setUpdating(true)} disabled={false}>⊚ Add new data</Button>}
                <span>&nbsp;&nbsp;</span>
                <Button variant="outline-info" onClick={switchFillingName}><b>＋</b> Complete Names</Button>
                <span>&nbsp;&nbsp;</span>
                <Button variant="outline-success" onClick={handleCSV}>⬇ Download CSV</Button>
                <span>&nbsp;&nbsp;</span>
                <Button variant="outline-danger" onClick={() => setShowDeleteConfirm(true)}>✘ Delete Data</Button>
                <span>&nbsp;&nbsp;</span>
                {/*<Button variant='outline-dark' onClick={signOut}>Ⓘ Sign Out</Button>*/}

            </ButtonToolbar>
        }
        <Modal show={showDeleteConfirm} onHide={() => setShowDeleteConfirm(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>Delete this course PDF and the data ?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="outline-secondary"
                        size={"sm"}
                        onClick={() => setShowDeleteConfirm(false)}>◃ Cancel</Button>
                <Button variant="danger"
                        onClick={handleDelete}>✘ Delete data !</Button>
            </Modal.Footer>
        </Modal>
        <br/>

    </>
};

export default ButtonsResults
