import React, {useEffect, useState} from 'react';
import {useAuth} from '../utils/authProvider';
import Form from "react-bootstrap/Form";
import {Button, Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";

/**
 * Component with the form to sign up
 * @returns {*}
 * @constructor
 */
const SignIn = () => {

    const {signIn} = useAuth();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const token = window.localStorage.getItem('token');

    // If user is (already) connected, redirect to homepage
    useEffect(() => {
        window.localStorage.getItem('token') && window.location.replace('/home')
    }, [token]);

    const handleSubmit = (e) => {
        e.preventDefault();
        signIn({username, password})
            .catch((err) => {
                setUsername('');
                setPassword('');
                setMessage(err.message);
            });


    };

    return (
        <Container>
            <Row>
                <Col>{/* Empty Col*/}</Col>
                <Col>
                    <br/>
                    <Card body>
                        <h2>IRCAD'S FORM READER</h2>
                        <Form onSubmit={handleSubmit}>
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" value={username}
                                          onChange={(e) => setUsername(e.target.value)}/>
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" value={password}
                                          onChange={(e) => setPassword(e.target.value)}/>
                            <br/>
                            <Button type="submit" variant="outline-info">Sign In</Button>
                        </Form>
                    </Card>
                    <br/>
                    {message && <>
                        <Alert variant="danger" onClose={() => setMessage('')} dismissible>
                            <p>{message}</p>
                        </Alert></>}
                </Col>
                <Col>{/* Empty Col*/}</Col>
            </Row>
        </Container>
    );
};

export default SignIn
