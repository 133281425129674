import {checkStatus} from "./Utils";

/**
 * Dictionary that group the api functions for medtronic
 * @type {{postFile: (function(*=, *=): Promise<any | any>), deleteCourseData: (function(*=): Promise<any | any>), sendUpgradeData: (function(*=, *=): Promise<any | any>)}}
 */
const medtronic_api = {
    /**
     * Upload a file
     * @param url
     * @param file
     * @returns {Promise<any>}
     */
    postFile: (url, file) => {
        const token = window.localStorage.getItem('token');
        return fetch(url, {
            headers:
                {
                    Authorization: "Bearer " + token
                },
            method: 'POST',
            body: file
        })
            .then(checkStatus)
            .then(res => res.json())
    },

    /**
     * Update a result
     * @param url
     * @param jsonData
     * @returns {Promise<any>}
     */
    sendUpgradeData: (url, jsonData) => {
        const token = window.localStorage.getItem('token');
        return fetch( url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: "Bearer " + token
            },
            method: 'PUT',
            body: jsonData
        })
            .then(checkStatus)
            .then(r => {return r.json();})
    },

    /**
     * Delete a result
     * @param url
     * @returns {Promise<any>}
     */
    deleteCourseData: (url) => {
        const token = window.localStorage.getItem('token');
        return fetch(url, {
            headers: {
                Authorization: "Bearer " + token
            },
            method: 'DELETE'
        })
            .then(checkStatus)
            .then((r) => {return r.json();})
    },

};

export default medtronic_api
